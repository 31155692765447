import enMessages from "./en";
import skMessages from "./sk";

export default {
  en: {
    translation: enMessages
  },
  sk: {
    translation: skMessages
  }
}