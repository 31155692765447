//import firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/database'

const config = {
    apiKey: "AIzaSyBoHXzZHPGx_3ZnqnwgOMrTXcZ0U5jqQwU",
    authDomain: "quickstart-1560858060611.firebaseapp.com",
    databaseURL: "https://quickstart-1560858060611.firebaseio.com",
    projectId: "quickstart-1560858060611",
    storageBucket: "quickstart-1560858060611.appspot.com",
    messagingSenderId: "470649329390",
    appId: "1:470649329390:web:90986522b03d9efbcd45ff"

};
firebase.initializeApp(config);
export default firebase;