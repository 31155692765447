import axios from "axios";
import CryptoJS from "crypto-js";
const config = require('../config')
const API_URL = config.API_HOST 

class AuthService {
  login(username, password) {
    var hash = CryptoJS.MD5(password).toString()
    if (username === "admin@judolevice.sk" && hash === "364ad7b8c9b07e77688e0f60cc44cd73") {
      localStorage.setItem("combatzone_user", "isLogged");
      return true
    }
    
    return false
    
  }

  logout() {
    console.log('logout()');
    localStorage.removeItem("combatzone_user")
    //localStorage.removeItem("combatzone_organization")
  }

  register(username, email, password) {
    console.log('register()');
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    const data = localStorage.getItem('combatzone_user')
    console.log('getCurrentUser '+data);
    return data
  }

  // getCurrentOrganization() {
  //   const data = JSON.parse(localStorage.getItem('combatzone_organization'))
  //   console.log('getCurrentOrganization '+data);
  //   return data
  // }

  isAuthenticated() {
    const user = this.getCurrentUser()
    if ( user ) {
      return true
    }
    return false
  }
}

export default new AuthService();