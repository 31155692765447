//import React, { useState, useEffect } from 'react'
import React from 'react'
import {HashRouter as Router, Switch, Route} from "react-router-dom"
// import { HashRouter as Router } from "react-router-dom";
import Login from './Pages/Login'
import Applications from './Pages/Applications'
import Registration from './Pages/Registration'

import AuthService from './_services/auth.service'


function App() {

  return (

    <div>      
      <Router>  
          <Switch>
            <Route exact path="/prihlaska" component={Registration}  />
            <Route exact path="/login" component={Login}  />
            <Route exact path="/logout" component={LogoutRoute}  />
            <PrivateRoute exact path="/zoznam-prihlasenych" component={Applications}  />            
            <Route path={'*'} component={Registration}  />            
          </Switch>
      </Router>
    </div>    
  )
}

const LogoutRoute = ({...rest}) => {
  AuthService.logout()

  return (
    <Route {...rest} render={props =>
          props.history.push("/login")
      }
    />
  )

}

const PrivateRoute = ({ component: Component, ...rest }) => {
  
  return (
    <Route {...rest} render={props =>
        AuthService.isAuthenticated() === true 
        ? 
          <Component {...props} />
        :
          props.history.push("/logout")
        
      }
    />
  )
}


export default App



