import React from 'react'
//import { useHistory } from 'react-router-dom'
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../_services/auth.service";

const SignInSchema = Yup.object().shape({
    username: Yup.string()
      .lowercase()
      .email('Must be a valid email!')
      .required('Required!'),
    password: Yup.string()
      .min(8, 'Minimum 8 characters required!')
      .required('Required!'),
  })


function Login(props) {
    console.log('Login.js');

    const initialValues = {
        username: '',
        password: '',
    }      

    const handleSubmit = (values) => {
        // disable refresh page after submitting - THIS IS DONE AUTOMATICLY BY FORMIK
        // event.preventDefault() 
        console.log(values)
        const login = AuthService.login(values.username, values.password)
        if (login) {
            console.log('login OK')       
            props.history.push("/zoznam-prihlasenych");     
        } else {
            console.log('not ok')
            props.history.push("/logout");
        }  


    }

    return (
    <div className="hold-transition login-page">
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            // onSubmit={(values, { setSubmitting }) => {
            //     setTimeout(() => {
            //     alert(JSON.stringify(values, null, 2));
            //     setSubmitting(false);
            //     }, 400);
            // }}
            validationSchema={SignInSchema}
        >
        {({ dirty, isValid, errors, touched,  }) => (
            
        <Form>
        <div className="errors">
            {errors.username && touched.username && <div>{errors.username}</div>}
            <ErrorMessage name="password" />
        </div>


        <div className="login-box">
        {/* <div className="login-logo">
            <a href="/"><b>IB</b> banking</a>
        </div> */}

        <div className="card">
            <div className="card-body login-card-body">
            {/* <p className="login-box-msg">Sign in to start your session</p> */}

                <div className="input-group mb-3">
                <Field name="username" type="email" autoComplete="off" className="form-control" placeholder="Email" />            
                {/* <div><ErrorMessage name="email" /></div> */}
                <div className="input-group-append">
                    <div className="input-group-text">
                    <span className="fas fa-envelope" />
                    </div>
                </div>                

                </div>
                <div className="input-group mb-3">
                <Field name="password" type="password" autoComplete="off" className="form-control" placeholder="Password" />                
                <div className="input-group-append">
                    <div className="input-group-text">
                    <span className="fas fa-lock" />
                    </div>
                </div>
                </div>                

                <div className="row">
                <div className="col-8">
                    {/* <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">
                        Remember Me
                    </label>
                    </div> */}
                </div>
                <div className="col-4">
                    <button type="submit" disabled={!isValid || !dirty} className="btn btn-primary btn-block">Sign In</button>
                </div>
                </div>

            {/* <div className="social-auth-links text-center mb-3">
                <p>- OR -</p>
                <a href="#" className="btn btn-block btn-primary">
                <i className="fab fa-facebook mr-2" /> Sign in using Facebook
                </a>
                <a href="#" className="btn btn-block btn-danger">
                <i className="fab fa-google-plus mr-2" /> Sign in using Google+
                </a>
            </div> */}

            {/* <p className="mb-1">
                <a href="forgot-password.html">I forgot my password</a>
            </p> */}
            {/* <p className="mb-0">
                <a href="register.html" className="text-center">Register a new membership</a>
            </p> */}
            
            </div>

        </div>
        
        
        </div>

        </Form>
        )}

        </Formik>
    </div>
    )
}

export default Login
